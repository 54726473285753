import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import style from './style';

import Icon from '../../../components/Icon';

function Button(props) {
  const { tag, size, icon, disabled, filled, block, bold, ghost, to, hash, children, loading, name, onClick, iconClassName } = props;
  const btnClassNames = classNames(
    style.btn,
    style[size],
    { [style.ghost]: ghost },
    { [style.filled]: filled },
    { [style.disabled]: disabled || loading },
    { [style.block]: block },
    { [style.only_icon]: icon && !children }
  );

  const textClassNames = classNames(
    style.text,
    { [style.has_icon]: icon },
    { [style.bold]: bold }
  );

  const TagProps = {
    disabled:  disabled || loading,
    className: btnClassNames,
    name,
    onClick,
  };
  let Tag;
  switch (tag) {
    case 'a':
      Tag = Link;
      TagProps.to = to;
      break;
    case 'hash':
      Tag = 'a';
      TagProps.href = `#${hash}`;
      break;
    case 'block':
      Tag = 'div';
      break;
    case 'reset':
      Tag = 'input';
      TagProps.type = 'reset';
      TagProps.value = children;
      return <Tag {...TagProps} />;
    default:
      Tag = 'button';
  }

  return (
    <Tag {...TagProps}>
      {icon
        ? <Icon className={classNames(style.icon, iconClassName)} glyph={icon} />
        : null
      }
      {children ? <span className={textClassNames}>{children}</span> : null}
      {loading ? <div className={style.loading} /> : null}
    </Tag>
  );
}

Button.propTypes = {
  tag:           PropTypes.string,
  size:          PropTypes.string,
  icon:          PropTypes.string,
  iconClassName: PropTypes.string,
  disabled:      PropTypes.bool,
  filled:        PropTypes.bool,
  block:         PropTypes.bool,
  bold:          PropTypes.bool,
  ghost:         PropTypes.bool,
  to:            PropTypes.string,
  hash:          PropTypes.string,
  loading:       PropTypes.bool,
  name:          PropTypes.string,
  onClick:       PropTypes.func,
  children:      PropTypes.node,
};

Button.defaultProps = {
  tag:  'button',
  size: 'md',
  to:   '',
};

Button.style = style;

export default Button;
