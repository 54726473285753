import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import style from './style';

import Icon from '../../../components/Icon';

class Button extends Component {
  static propTypes = {
    tag:           PropTypes.string,
    size:          PropTypes.string,
    icon:          PropTypes.string,
    iconClassName: PropTypes.string,
    disabled:      PropTypes.bool,
    filled:        PropTypes.bool,
    block:         PropTypes.bool,
    bold:          PropTypes.bool,
    ghost:         PropTypes.bool,
    to:            PropTypes.string,
    loading:       PropTypes.bool,
    name:          PropTypes.string,
    onClick:       PropTypes.func,
    children:      PropTypes.node,
    type:          PropTypes.string,
  };

  static defaultProps = {
    tag:  'button',
    size: 'md',
    to:   '',
  };

  constructor(props, context) {
    super(props, context);

    this.click = this.click.bind(this);
  }

  click(e) {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(e);
    }
  }

  render() {
    const { tag, size, icon, disabled, filled, block, bold, ghost, to, children, loading, name, type } = this.props;
    const btnClassNames = classNames(
      style.btn,
      style[size],
      ghost && style.ghost,
      filled && style.filled,
      (disabled || loading) && style.disabled,
      block && style.block,
      (icon && !children) && style.only_icon,
      loading && style.loading
    );

    const textClassNames = classNames(
      style.text,
      icon && style.has_icon,
      bold && style.bold
    );

    const TagProps = {
      disabled:  disabled || loading,
      className: btnClassNames,
      name,
    };
    let Tag;
    switch (tag) {
      case 'a':
        if (to.indexOf('http') === 0) {
          Tag = 'a';
          TagProps.href = to;
          TagProps.target = '_blank';
        } else {
          Tag = Link;
          TagProps.to = to;
        }
        TagProps.onClick = this.click;
        break;
      case 'block':
        Tag = 'div';
        break;
      default:
        Tag = 'button';
        TagProps.onClick = this.click;
        TagProps.type = type;
    }

    return (
      <Tag {...TagProps}>
        {icon && <Icon className={classNames(style.icon, this.props.iconClassName)} glyph={icon} />}
        {children && <span className={textClassNames}>{children}</span>}
      </Tag>
    );
  }
}

Button.style = style;

export default Button;
