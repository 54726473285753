import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './style';

const svgIcons = require.context('../../styles/svg_sprites/', false, /.*\.svg$/);
// имена имеют вид './back.svg'

const icons = svgIcons.keys().reduce((sprite, icon) => {
  const iconInfo = svgIcons(icon).default;
  const viewBox = iconInfo.viewBox.split(' ');
  return {
    ...sprite,
    [icon.slice(2).split('.')[0]]: {
      name:    `#${iconInfo.id}`,
      viewBox: [viewBox[2], viewBox[3]],
    },
  };
}, {});

export default function Icon({ width, height, glyph, className }) {
  const icon = icons[glyph];
  if (!icon) return null;

  const iconWidth = width || icon.viewBox[0] || 16;
  const iconHeight = height || icon.viewBox[1] || 16;
  return (
    <svg className={classnames(style.icon, className)} width={iconWidth} height={iconHeight}>
      <use xlinkHref={icon.name} />
    </svg>
  );
}

Icon.propTypes = {
  width:     PropTypes.number,
  height:    PropTypes.number,
  glyph:     PropTypes.string,
  className: PropTypes.string,
};
