import * as React from 'react';
import style      from 'src/shared/components/Price/style.styl';

const regExp = /[.,]/;
const toMoney = ( amount ) => amount.replace(/./g, ( c, i, a ) => {
  return i > 0 && (a.length - i) % 3 === 0 ? `\u00A0${c}` : c;
});

export default {
  RUB: ( value ) => {
    const [rub, kop] = value.split(regExp);
    const price = toMoney(rub);
    const kopeiki = +kop ? <span className={style.sm}>,{kop}</span> : '';
    return <span>{price}{kopeiki}&nbsp;<span className={style.r}>р</span></span>;
  },
  BYN: ( value, rate ) => {
    value = '' + Math.ceil(+value.replace(',', '.') / rate);
    const price = toMoney(value);
    return <span>{price}&nbsp;BYN</span>;
  },
  KZT: ( value, rate ) => {
    value = '' + Math.ceil(+value.replace(',', '.') / rate * 100);
    const price = toMoney(value);
    return <span>{price}&nbsp;т.</span>;
  },
}
