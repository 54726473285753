import * as React           from 'react';
import { connect }          from 'react-redux';
import PropTypes            from 'prop-types';
import { connectAppConfig } from 'src/shared/components/AppConfig';
import rates                from "src/config/rates";
import style                from './style.styl';

class Price extends React.PureComponent {
  static propTypes = {
    value:         PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    pricesVisible: PropTypes.bool,
    canViewPrices: PropTypes.bool,
  };

  render() {
    let { config, value, pricesVisible, canViewPrices } = this.props;
    if (config.PRICES_HIDDEN === true) {
      if (!canViewPrices) return null;
      if ((pricesVisible === undefined || !pricesVisible)) return null;
    }

    const priceCoefficient = config.PRICE_COEFFICIENT;
    if (priceCoefficient && priceCoefficient !== 1) {
      value = (+value.replace(',', '.') * priceCoefficient).toFixed(2);
    }
    const rate = rates[config.CURRENCY] || rates['RUB'];
    return rate('' + value, config.RATE);
  }
}

const mapStateToProps = state => ({
  pricesVisible: state.pricesVisible,
  canViewPrices: state.profile && state.profile.canViewPrices,
});
export default connectAppConfig(connect(mapStateToProps)(Price));
